<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new justify-content-normal">

        <v-text-field @keyup="searchDatas" v-model="domainsSearch" :label="$t('serve.new.searchDomain')" class="pt-2 flex-grow-0">
            <template v-slot:append>
                <v-icon color="red" @click="cleanSearch">
                    mdi-close
                </v-icon>
            </template>
        </v-text-field>
        
        <div class="mt-3 page-content">
            <v-row class="flex-grow-0">
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="(element, key) in this.domainsSelectedFiltered" :key="key">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                            @click="selectDomain(element.id, element.name)"
                    >
                            <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                                <i class="edt-info"></i>
                            </span>
                        <div>{{ element.name }}</div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DomainStep",
        props: ['domains'],
        data() {
            return {
                domainsSelected: [],
                domainsSelectedFiltered: [],
                domainsSearch: ''
            };
        },
        methods: {
            selectDomain(elementId, name) {
                if (!elementId && typeof name == 'number') {
                    elementId = name
                }
                var subLevel = this.domains.domainsSubLevel[elementId]
                if (subLevel) {
                    this.domainsSelectedFiltered = subLevel
                    this.domainsSelected = subLevel
                    this.domainsSearch = ''
                } else {
                    this.submitForm(elementId)
                }
            },
            searchDatas() {
                var realEstateTmp = [];
                Object.keys(this.domainsSelected).forEach((key) => {
                    if (this.domainsSelected[key].name.toLowerCase().includes(this.domainsSearch.toLowerCase())) {
                        realEstateTmp.push(this.domainsSelected[key])
                    }
                })
                this.domainsSelectedFiltered = realEstateTmp;
            },
            cleanSearch() {
                this.domainsSearch = ''
              this.domainsSelectedFiltered = this.domainsSelected
            },
            submitForm(key) {
                this.$emit("updatevalue", "domainTreeId", key);
                this.$emit("incrementstep");
                this.$emit("loadconsigne");
            }
        },
        created() {
            this.domainsSelected = this.domains.domainsSelected
            this.domainsSelectedFiltered = this.domains.domainsSelected
        }
    };
</script>